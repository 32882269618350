<template>
  <MainPage msg="Welcome to Grounds" />
</template>

<script>
import MainPage from "./components/MainPage.vue";

export default {
  name: "App",
  components: {
    MainPage,
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  // width: 100%;
  // height: 100%;
  // overflow: hidden;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: black;
  // width: 100%;
  // height: 100%;
  margin: 0px;
}
</style>
