<template>
  <div class="main-page">
    <header>
      <nav>
        <!-- <div class="logo">Grounds AI</div> -->
        <div class="logo">
          <img src="@/assets/groundsai.png" alt="Grounds AI" class="logo-image">
        </div>
        <div class="menu-icon" @click="toggleMenu">
          <i :class="{'fas fa-bars': !showMenu, 'fas fa-times': showMenu}"></i>
        </div>
        <ul :class="{'show-menu': showMenu}">
          <li><a href="#solutions" @click="closeMenu">Solutions</a></li>
          <li><a href="#expertise" @click="closeMenu">Expertise</a></li>
          <li><a href="#about" @click="closeMenu">About</a></li>
          <li><a href="#contact" @click="closeMenu">Contact</a></li>
        </ul>
      </nav>
    </header>

    <section id="home" class="hero">
      <div class="hero-content">
        <h1>AI Innovation Built on Sound Legal Ground</h1>
        <p>Enterprise-grade AI solutions, built by deep learning engineers who are also IP lawyers.</p>
        <div class="cta-container">
          <a href="#contact" class="cta-button primary">Schedule Consultation</a>
          <a href="#solutions" class="cta-button secondary">Explore Solutions</a>
        </div>
      </div>
    </section>

    <section id="solutions" class="solutions">
      <h2>Our Solutions</h2>
      <div class="solutions-grid">
        <div class="solution-item">
          <i class="fas fa-shield-alt"></i>
          <h3>Compliant AI Deployment</h3>
          <p>Deploy AI solutions while protecting trade secrets, maintaining privilege, and ensuring regulatory compliance.</p>
        </div>
        <div class="solution-item">
          <i class="fas fa-robot"></i>
          <h3>Custom AI Development</h3>
          <p>Tailored AI solutions spanning language models, computer vision, and autonomous agent systems.</p>
        </div>
        <div class="solution-item">
          <i class="fas fa-dna"></i>
          <h3>BioAI Solutions</h3>
          <p>Specialized AI applications for computational biology and healthcare, backed by academic expertise.</p>
        </div>
        <div class="solution-item">
          <i class="fas fa-balance-scale"></i>
          <h3>Legal Tech Products</h3>
          <p>Advanced tools for document automation, multi-agent negotiations, and legal workflow optimization.</p>
        </div>
      </div>
    </section>

    <section id="expertise" class="expertise">
      <div class="expertise-content">
        <h2>Why Choose Grounds AI</h2>
        <div class="expertise-grid">
          <div class="expertise-item">
            <h3>Legal + Technical Expertise</h3>
            <p>Founded by IP lawyers and AI developers, we uniquely understand both the technical and legal challenges of AI deployment.</p>
          </div>
          <div class="expertise-item">
            <h3>Enterprise Security</h3>
            <p>Battle-tested solutions that protect confidentiality, trade secrets, and attorney-client privilege.</p>
          </div>
          <div class="expertise-item">
            <h3>Research Background</h3>
            <p>Deep academic expertise in computational biology and AI, with numerous peer-reviewed publications.</p>
          </div>
          <div class="expertise-item">
            <h3>Proven Track Record</h3>
            <p>Successfully delivered AI solutions across legal tech, computer vision, and life sciences applications.</p>
          </div>
        </div>
      </div>
    </section>

    <section id="about" class="team-section">
      <h2>Leadership</h2>
      <div class="team-container">
        <div class="team-member" v-for="member in teamMembers" :key="member.name">
          <img :src="member.image" :alt="`${member.name}, ${member.role}`" class="member-photo"/>
          <h3 class="member-name">{{ member.name }}</h3>
          <p class="member-role">{{ member.role }}</p>
          <p class="member-bio">{{ member.bio }}</p>
          <div class="member-expertise">
            <span v-for="skill in member.expertise" :key="skill" class="expertise-tag">
              {{ skill }}
            </span>
          </div>
        </div>
      </div>
    </section>


    <div class="legal-services">
      <h3>Innovating at the Intersection of Law and AI</h3>
      <p>Visit our affiliated law firm, 
        <a href="https://www.groundsllp.com/" target="_blank" rel="noopener" class="legal-link">Grounds LLP</a>, 
        where we leverage our custom AI tools to provide exceptional legal services.</p>
    </div>

    <!-- <section id="contact" class="contact">
      <div class="contact-container"> -->
        <!-- <div class="contact-content">
          <h2>Get Started</h2>
          <p>Ready to deploy AI with confidence? Let's discuss your needs.</p>
        </div> -->
        <!-- <form @submit.prevent="handleFormSubmit" class="contact-form">
          <div class="form-group">
            <input type="text" name="entry.1024150449" placeholder="Name" required>
          </div>
          <div class="form-group">
            <input type="email" name="entry.56891630" placeholder="Email" required>
          </div>
          <div class="form-group">
            <select name="entry.interest" required>
              <option value="">Select Area of Interest</option>
              <option value="compliance">AI Compliance & Deployment</option>
              <option value="custom">Custom AI Development</option>
              <option value="bio">BioAI Solutions</option>
              <option value="legal">Legal Tech</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div class="form-group">
            <textarea name="entry.164989330" placeholder="Tell us about your project or challenge" required></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form> -->
        <section id="contact" class="contact">
          <div class="contact-container">
            <div class="contact-content">
              <h2>Get Started</h2>
              <p>Ready to deploy AI with confidence? Let's discuss your needs.</p>
              <div class="contact-emails">
                <div class="email-contact">
                  <p>Contact Joe at <a href="mailto:joe@grounds.ai" class="email-link">joe@grounds.ai</a></p>
                </div>
                <div class="email-contact">
                  <p>Contact Bahrad at <a href="mailto:bahrad@grounds.ai" class="email-link">bahrad@grounds.ai</a></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      <!-- </div>
    </section> -->

    <footer>
      <div class="footer-content">
        <div class="footer-section">
          <h3>Grounds AI</h3>
          <p>AI innovation with legal confidence</p>
        </div>
        <div class="footer-section">
          <h3>Locations</h3>
          <p>New York | Southern California</p>
        </div>
        <div class="footer-section">
          <h3>Follow Us</h3>
          <div class="social-links">
            <!-- <a href="#" class="social-link"><i class="fab fa-linkedin"></i></a> -->
            <a href="https://x.com/groundsai" class="social-link"><i class="fab fa-twitter"></i></a>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>&copy; 2024 Grounds, Inc. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  data() {
    return {
      showMenu: false,
      teamMembers: [
        {
          name: 'Bahrad Sokhansanj, Ph.D.',
          role: 'co-CEO',
          image: '/bahrad.jpg',
          bio: 'Deep learning engineer and IP lawyer with extensive research background in computational biology. Former university professor with numerous peer-reviewed publications.',
          expertise: ['AI & Deep Learning', 'Biomedical Engineering', 'Distributed Computing', 'Legal AI']
        },
        {
          name: 'Joe Wilbert',
          role: 'co-CEO',
          image: '/joe.jpg',
          bio: 'Full-stack developer and IP lawyer specializing in AI applications. Expert in computer vision and natural language processing who has developed multiple applications on virtually all platforms',
          expertise: ['AI & Deep Learning', 'Computer Vision', 'NLP', 'Full-Stack Development', 'Legal AI'] 
        }
      ]
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
    },
    handleFormSubmit(event) {
      const form = event.target;
      const formData = new FormData(form);

      fetch(form.action, {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      })
        .then(() => {
          form.reset();
          alert('Thank you for your message! We will get back to you soon.');
        })
        .catch(error => {
          console.error('Error:', error);
          alert('Something went wrong. Please try again later.');
        });
    }
  }
};
</script>

<style scoped>
/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-page {
  font-family: 'Inter', 'Segoe UI', sans-serif;
  color: #1a1a1a;
  line-height: 1.6;
}

/* Header styles */
header {
  background-color: rgba(255, 255, 255, 0.98);
  padding: 1rem 1.5rem; 
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

nav {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: #2563eb;
}

nav ul {
  display: flex;
  gap: 2rem;
  list-style: none;
}

nav a {
  color: #1a1a1a;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #2563eb;
}

/* Hero section */
.hero {
  padding: 8rem 2rem 6rem;
  background: linear-gradient(135deg, #1e3a8a 0%, #3b82f6 100%);
  color: white;
  text-align: center;
}

.hero-content {
  max-width: 800px;
  margin: 4rem auto;
}

.hero h1 {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.cta-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.cta-button {
  padding: 0.875rem 2rem;
  border-radius: 0.5rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.cta-button.primary {
  background-color: white;
  color: #1e3a8a;
}

.cta-button.secondary {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Solutions section */
.solutions {
  padding: 6rem 2rem;
  background-color: #f8fafc;
}

.solutions h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.solutions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.solution-item {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.solution-item:hover {
  transform: translateY(-5px);
}

.solution-item i {
  font-size: 2rem;
  color: #2563eb;
  margin-bottom: 1rem;
}

.solution-item h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

/* Expertise section */
.expertise {
  padding: 6rem 2rem;
  background-color: white;
}

.expertise-content {
  max-width: 1200px;
  margin: 0 auto;
}

.expertise h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.expertise-item {
  padding: 2rem;
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
}

.expertise-item h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #2563eb;
}

.solutions-grid, .expertise-grid {
  display: grid;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  /* Update grid layout to prevent 3+1 configuration */
  grid-template-columns: repeat(2, 1fr);
}

/* Stack vertically on smaller screens */
@media (max-width: 768px) {
  .solutions-grid, .expertise-grid {
    grid-template-columns: 1fr;
  }
}

/* Ensure minimum width for grid items */
.solution-item, .expertise-item {
  min-width: 280px;  /* Prevent items from getting too narrow */
  width: 100%;
}

/* Team section */
.team-section {
  padding: 6rem 2rem;
  background-color: #f8fafc;
}

.team-container {
  max-width: 1200px;
  margin:  auto;
  padding-top: 36px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
}

.team-member {
  text-align: center;
}

.member-photo {
  width: 200px;
  height: 200px;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  object-fit: cover;
}

.member-name {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.member-role {
  color: #2563eb;
  font-weight: 600;
  margin-bottom: 1rem;
}

.member-bio {
  margin-bottom: 1.5rem;
}

.expertise-tag {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #e5e7eb;
  border-radius: 2rem;
  margin: 0.25rem;
  font-size: 0.875rem;
}

/* Contact section */
.contact {
  padding: 6rem 2rem;
  background-color: white;
}

.contact-container {
  max-width: 800px;
  margin: 0 auto;
}

.contact-content {
  text-align: center;
  margin-bottom: 3rem;
}

.contact-form {
  display: grid;
  gap: 1.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.form-group textarea {
  height: 150px;
}
.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

button[type="submit"] {
  background-color: #2563eb;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

button[type="submit"]:hover {
  background-color: #1d4ed8;
}

/* Footer styles */
footer {
  background-color: #1a1a1a;
  color: white;
  padding: 4rem 2rem 2rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 3rem;
  margin-bottom: 3rem;
}

.footer-section h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: white;
}

.footer-section p {
  color: #9ca3af;
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.social-link {
  color: #9ca3af;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: white;
}

.footer-bottom {
  border-top: 1px solid #374151;
  padding-top: 2rem;
  text-align: center;
}

.footer-bottom p {
  color: #9ca3af;
  font-size: 0.875rem;
}

/* Mobile menu styles */
.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
}

/* Responsive design */
@media (max-width: 768px) {
  /* Header */
  header {
    padding: 0.75rem 1rem; /* Slightly reduced padding on mobile */
  }

  .menu-icon {
    display: block;
  }

  nav ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 1rem;
    flex-direction: column;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  nav ul.show-menu {
    display: flex;
  }

  nav ul li {
    margin: 1rem 0;
  }

  /* Hero */
  .hero {
    padding: 6rem 1rem 4rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.125rem;
  }

  .cta-container {
    flex-direction: column;
  }

  /* Sections */
  .solutions,
  .expertise,
  .team-section,
  .contact {
    padding: 4rem 1rem;
  }

  /* Grid layouts */
  .solutions-grid,
  .expertise-grid {
    grid-template-columns: 1fr;
  }

  /* Team members */
  .team-container {
    gap: 2rem;
  }

  .member-photo {
    width: 160px;
    height: 160px;
  }

  /* Footer */
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.solution-item,
.expertise-item,
.team-member {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Utility classes */
.text-gradient {
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient {
  background: linear-gradient(135deg, #1e3a8a 0%, #3b82f6 100%);
}

.shadow-soft {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.shadow-medium {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.transition-all {
  transition: all 0.3s ease;
}

.logo-image {
  height: 32px;
  width: auto;
}
/* Updated legal services section styling */
.legal-services {
  background: linear-gradient(135deg, #1a1a1a 0%, #2d3748 100%);
  color: white;
  padding: 4rem 2rem;
  margin: 0;  /* Remove margin to prevent gaps */
  width: 100%;
  position: relative;
  z-index: 1;
}

.legal-services h3 {
  color: #60a5fa;  /* Light blue color */
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.legal-services p {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.25rem;
  line-height: 1.7;
  text-align: center;
  color: #e5e7eb;  /* Light gray text */
}

.legal-services .legal-link {
  color: #60a5fa;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  border-bottom: 1px solid transparent;
}

.legal-services .legal-link:hover {
  color: #93c5fd;
  border-bottom: 1px solid #93c5fd;
}

/* Update contact section to ensure proper flow */
.contact {
  background-color: white;
  padding: 6rem 2rem;
  margin: 0;
  width: 100%;
}

/* Ensure proper container sizing */
.contact-container {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.contact-emails {
  margin-top: 2rem;
  padding: 2rem;
  background-color: #f8fafc;
  border-radius: 0.5rem;
  text-align: center;
}

.email-contact {
  margin: 1rem 0;
  font-size: 1.25rem;
}

.email-link {
  color: #2563eb;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.email-link:hover {
  color: #1d4ed8;
  text-decoration: underline;
}

</style>